import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { distributeCoupons, updateCoupon } from '../services/api';
import { Box, Card, CardContent, CardMedia, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Alert } from '@mui/material';
import { styled } from '@mui/system';

interface Coupon {
  code: string;
  description: string;
  expiration_date: string;
}

interface UserCoupon {
  id: number;
  coupon: Coupon;
  is_used: boolean;
  assigned_date: string;
}

const OverlayText = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: 'rgba(255, 0, 0, 0.7)',
  fontSize: '2rem',
  fontWeight: 'bold',
  textAlign: 'center',
  pointerEvents: 'none',
}));

const CouponList: React.FC = () => {
  const { token } = useAuth();
  const [coupons, setCoupons] = useState<UserCoupon[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCoupon, setSelectedCoupon] = useState<UserCoupon | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  useEffect(() => {
    const fetchCoupons = async () => {
      if (token) {
        try {
          setLoading(true);
          const response = await distributeCoupons(token);
          console.log(response);  // レスポンスをコンソールに出力
          setCoupons(response.data);
        } catch (error) {
          console.error('Failed to fetch coupons:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchCoupons();
  }, [token]);

  const handleUseCoupon = async () => {
    if (token && selectedCoupon) {
      try {
        const response = await updateCoupon(token, selectedCoupon.id);
        if (response.data.status === 'success') {
          const updatedCoupons = coupons.map(coupon => 
            coupon.id === selectedCoupon.id ? { ...coupon, is_used: true } : coupon
          );
          setCoupons(updatedCoupons);
          setSnackbarMessage('クーポンが使用されました。');
          setSnackbarSeverity('success');
        } else {
          setSnackbarMessage(response.data.message);
          setSnackbarSeverity('error');
        }
      } catch (error) {
        console.error('Failed to use coupon:', error);
        setSnackbarMessage('クーポンの使用に失敗しました。');
        setSnackbarSeverity('error');
      } finally {
        setDialogOpen(false);
        setSnackbarOpen(true);
      }
    }
  };

  return (
    <Box sx={{ marginTop: 6, maxHeight: '80vh', overflowY: 'auto' }}>
      <Typography variant="h6" align="center" gutterBottom>
        獲得特典一覧
      </Typography>
      {coupons.map((userCoupon, index) => (
        <Card key={index} sx={{ position: 'relative', display: 'flex', marginBottom: 2, background: '#FFF0F5' }}>
          {userCoupon.is_used && <OverlayText>使用済み</OverlayText>}
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <CardContent>
              <Typography variant="h5" component="div">
                {userCoupon.coupon.code}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {userCoupon.coupon.description}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                有効期限: {userCoupon.coupon.expiration_date}
              </Typography>
              {!userCoupon.is_used && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setSelectedCoupon(userCoupon);
                    setDialogOpen(true);
                  }}
                >
                  使用する
                </Button>
              )}
            </CardContent>
          </Box>
        </Card>
      ))}

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>クーポン使用確認</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            このクーポンを使用しますか？
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleUseCoupon} color="primary">
            使用する
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CouponList;
