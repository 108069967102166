import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getProtectedResourceDetail } from '../services/api';
import { AppBar, Box, Button, Grid, Paper, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import SettingsIcon from '@mui/icons-material/Settings';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import QrCodeIcon from '@mui/icons-material/QrCode';
import AnnouncementIcon from '@mui/icons-material/Announcement'; // お知らせアイコン
import { useNavigate } from 'react-router-dom';
import IDComponent from './ID';
import UserSetting from './UserSetting';
import CouponList from './CouponList';
import AnnouncementList from './AnnouncementList'; // 追加

interface UserInfo {
  name: string;
  email: string;
  id: string;
  rank: number;
  points: number;
  times: number;
  is_staff: boolean;
}

const estimateRank = (points: number | undefined, times: number | undefined): number => {
  var rank = 0;

  if (points !== undefined && times !== undefined) {
    const sum = points + times
    if (sum >= 10) {
      rank = 1;
    } 
    if (sum >= 15) {
      rank = 2;
    } 
    if (sum >= 30) {
      rank = 3;
    } 
    if (sum >= 45) {
      rank = 4;
    } 
    if (sum >= 55) {
      rank = 5;
    } 
    if (sum >= 80) {
      rank = 6;
    }
  }
  return rank;
}

const Home: React.FC = () => {
  const navigate = useNavigate();
  const { token, logout, isStaff, setIsStaff } = useAuth();
  const [message, setMessage] = useState<UserInfo>();
  const [pageGuide, setPageGuide] = useState<'qr' | 'setting' | 'coupons' | 'announcements'>('qr');

  const seacret_key = "moka";

  useEffect(() => {
    const fetchProtectedResource = async () => {
      if (token) {
        try {
          const response = await getProtectedResourceDetail(token);
          console.log(response);
          setIsStaff(response.data.is_staff);
          setMessage(response.data);
        } catch (error: any) {
          console.error('Failed to fetch protected resource:', error);
          alert('エラーが発生しました。再度ログインしてください。');
          logout();
        }
      }
    };
    fetchProtectedResource();
  }, [token]);

  const pageHandler = (page: string) => {
    if (page === 'qr') {
      return (
        <IDComponent
          message={message}
          estimateRank={estimateRank}
          seacret_key={seacret_key}
        />
      );
    } else if (page === 'setting') {
      return (
        <UserSetting
          message={message}
          estimateRank={estimateRank}
          seacret_key={seacret_key}
        />
      );
    } else if (page === 'coupons') {
      return <CouponList />;
    } else if (page === 'announcements') {
      return <AnnouncementList />;
    }
  };

  if (!isStaff) {
    return (
      <div>
        <Box
          sx={{
            position: 'relative',
            height: '90vh', // 画面全体の高さに合わせる
            display: 'flex',
            justifyContent: 'center',
            overflow: 'hidden',
          }}
        >
          {pageHandler(pageGuide)}
        </Box>
        <Paper elevation={8} sx={{ position: 'fixed', bottom: 0, height: 'auto', width: '100%', zIndex: 2 }}>
          <Grid container justifyContent={'space-between'}>
            <Button
              onClick={() => setPageGuide('announcements')}
              sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'column', width: '25%', padding: 1.5 }}
            >
              <AnnouncementIcon sx={{ alignSelf: 'center', color: blue[700] }} />
              <Typography variant='body1' align='center' color={blue[700]} sx={{ fontSize: 12 }}>
                お知らせ
              </Typography>
            </Button>
            <Button
              onClick={() => setPageGuide('qr')}
              sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'column', width: '25%', padding: 1.5 }}
            >
              <QrCodeIcon sx={{ alignSelf: 'center', color: blue[700] }} />
              <Typography variant='body1' align='center' color={blue[700]} sx={{ fontSize: 12 }}>
                会員証
              </Typography>
            </Button>
            <Button
              onClick={() => setPageGuide('coupons')}
              sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'column', width: '25%', padding: 1.5 }}
            >
              <CardGiftcardIcon sx={{ alignSelf: 'center', color: blue[700] }} />
              <Typography variant='body1' align='center' color={blue[700]} sx={{ fontSize: 12 }}>
                特典
              </Typography>
            </Button>
            <Button
              onClick={() => setPageGuide('setting')}
              sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'column', width: '25%', padding: 1.5 }}
            >
              <SettingsIcon sx={{ alignSelf: 'center', color: blue[700] }} />
              <Typography variant='body1' align='center' color={blue[700]} sx={{ fontSize: 12 }}>
                設定
              </Typography>
            </Button>
          </Grid>
        </Paper>
      </div>
    );
  } else {
    return (
      <>
        {navigate('/staff-dashboard')}
      </>
    )
  }
};

export default Home;
