import React, { useState } from 'react';
import { Box, TextField, Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { RequestResetPassword } from '../services/api'; // 関数のインポートパスを適宜変更してください

const PasswordResetForm: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            await RequestResetPassword(email);
            setMessage('パスワードリセットのリクエストが送信されました。');
        } catch (error) {
            setMessage('エラーが発生しました。もう一度お試しください。');
        }
    };

    return (
        <Container maxWidth="sm">
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    mt: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                <Typography variant="h6" component="div">
                    パスワードリセットのリクエスト
                </Typography>
                <TextField
                    label="メールアドレス"
                    type="email"
                    variant="outlined"
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                {message && (
                    <Typography variant="body2" color="textSecondary">
                        {message}
                    </Typography>
                )}
                <Button type="submit" variant="contained" color="primary">
                    リクエスト送信
                </Button>
                <Button variant="outlined" color="secondary" onClick={() => navigate('/')}>
                    戻る
                </Button>
            </Box>
        </Container>
    );
};

export default PasswordResetForm;
