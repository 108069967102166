// PasswordChangeForm.tsx
import React, { useState } from 'react';
import { Box, TextField, Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { UpsdatePassWord } from '../services/api';
import { useAuth } from '../contexts/AuthContext';

const PasswordChangeForm: React.FC = () => {
    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [reNewPassword, setReNewPassword] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const navigate = useNavigate();
    const { token } = useAuth();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            if (token !== null) {
                await UpsdatePassWord(newPassword, reNewPassword, currentPassword, token);
            }
            setMessage('パスワードを更新しました');
        } catch (error) {
            setMessage('パスワードの更新に失敗しました');
        }
    };

    return (
        <Container maxWidth="sm">
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    mt: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                <Typography variant="h6" component="div">
                    パスワードの変更
                </Typography>
                <TextField
                    label="現在のパスワード"
                    type="password"
                    variant="outlined"
                    fullWidth
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    required
                />
                <TextField
                    label="新しいパスワード"
                    type="password"
                    variant="outlined"
                    fullWidth
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                />
                <TextField
                    label="新しいパスワード（確認）"
                    type="password"
                    variant="outlined"
                    fullWidth
                    value={reNewPassword}
                    onChange={(e) => setReNewPassword(e.target.value)}
                    required
                />
                {message && (
                    <Typography variant="body2" color="textSecondary">
                        {message}
                    </Typography>
                )}
                <Button type="submit" variant="contained" color="primary">
                    パスワードを更新
                </Button>
                <Button variant="outlined" color="secondary" onClick={() => navigate('/')}>
                    戻る
                </Button>
            </Box>
        </Container>
    );
};

export default PasswordChangeForm;
