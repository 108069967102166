// NameChangeForm.tsx
import React, { useState } from 'react';
import { Box, TextField, Button, Container, Typography } from '@mui/material';
import axios from 'axios';
import { updateProtectedResource } from '../services/api';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const NameChangeForm: React.FC = () => {
    const [name, setName] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const { token } = useAuth();
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            if (token !== null) {
                await updateProtectedResource(name, token);
            }
            setMessage('名前を更新しました');
        } catch (error) {
            setMessage('名前の更新に失敗しました');
        }

    };

    return (
        <Container maxWidth="sm">
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    mt: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                <Typography variant="h6" component="div">
                    名前の変更
                </Typography>
                <TextField
                    label="新しい名前"
                    variant="outlined"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
                {message && (
                    <Typography variant="body2" color="textSecondary">
                        {message}
                    </Typography>
                )}
                <Button type="submit" variant="contained" color="primary">
                    名前を更新
                </Button>
                <Button variant="outlined" color="secondary" onClick={() => navigate('/')}>
                    戻る
                </Button>
            </Box>
        </Container>
    );
};

export default NameChangeForm;
