import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { checkActivation, incrementUserParticipation, preRegisterCode } from '../services/api';
import { isAxiosError } from 'axios';
import { Box, Button, Paper, TextField, Typography } from '@mui/material';

const PreRegister: React.FC = () => {
  const { preRegister } = useAuth();
  const [email, setEmail] = useState('');
  const [name, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [re_password, setRePassword] = useState('');
  const [errorMessage_name, setErrorMessageName] = useState('');
  const [errorMessage_password, setErrorMessagePassword] = useState('');
  const [errorMessage_email, setErrorMessageEmail] = useState('');

  const [activation, setIsActivation] = useState(false);
  const [code, setCode] = useState('');
  const [errorMessage_code, setErrorMessageCode] = useState('');

  const handleActivate = async (event: React.FormEvent) => {
    event.preventDefault();
    setErrorMessageCode('')
    try {
      const response = await checkActivation(code)
      if (!response.data.valid) {
        setErrorMessageCode('シリアルコードが有効ではありません')
      } else if (response.data.used) {
        setErrorMessageCode('このコードはすでに使われています')
      } else {
        setIsActivation(true);
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setErrorMessageEmail('');
    setErrorMessageName('');
    setErrorMessagePassword('');
    try {
      await preRegister(email, name, password, re_password);
      await preRegisterCode(email, code)
      alert('仮登録しました。メールを確認して本登録を完了してください。');
    } catch (error) {
      console.error('Pre-registration failed:', error);
      if (isAxiosError(error) && error.response?.status === 400) {
        let _message = ""
        if (error.response.data.email) {
          for (let item of error.response.data.email) {
            _message += item
          }
          setErrorMessageEmail(_message)
        } else if (error.response.data.name) {
          for (let item of error.response.data.name) {
            _message += item
          }
          setErrorMessageName(_message)
        }
        else if (error.response.data.password) {
          for (let item of error.response.data.password) {
            _message += item
          }
          setErrorMessagePassword(_message)
        }
        else if (error.response.data.non_field_errors) {
          _message = "パスワードが一致しません。"
          setErrorMessagePassword(_message)
        }
      }
    }
  };

  return (
    <div>
      <Box
        sx={{
          position: 'relative',
          height: '80vh', // 画面全体の高さに合わせる
          display: 'flex',
          justifyContent: 'center',
          overflow: 'hidden',
        }}
      >
        <Box sx={{
          minWidth: "400px",
          width: { xs: "100%" },
          height: { xs: "30vh" },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden', // Boxの境界を超えた部分を隠す 
          position: 'fixed',
          marginTop: 4
        }}>
          <img src='/image/top.png' style={{ width: "100%", objectFit: 'cover' }} />
        </Box>
        <Paper
          elevation={6}
          square={false}
          sx={{
            position: 'absolute',
            padding: 3,
            margin: 20,
            width: '70vw', // 適切な幅に調整
            maxWidth: 600, // 最大幅を設定
            zIndex: 1,
            backgroundColor: 'background.paper', // 背景色を設定
          }}
        >
          {(!activation) ?
            <form onSubmit={handleActivate}>
              <Typography variant='body2'>
                購入したシリアルコードを入力してください。コードは、ライブ物販でのみ購入できます。
              </Typography>
              <TextField
                fullWidth
                label="アクティベーションコード"
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                sx={{
                  marginTop: 3
                }}
              />
              <Typography variant='body2' color={'red'}>
                {errorMessage_code}
              </Typography>
              <Button
                type="submit"
                variant="contained"
                color="success"
                sx={{
                  width: '100%',
                  marginTop: 3
                }}
              >
                コード確認
              </Button>
            </form>
            :
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="メールアドレス"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  marginTop: 1
                }}
              />
              {errorMessage_email}
              <TextField
                fullWidth
                label="ニックネーム"
                type="text"
                value={name}
                onChange={(e) => setUsername(e.target.value)}
                sx={{
                  marginTop: 1
                }}
              />
              {errorMessage_name}
              <TextField
                fullWidth
                label="パスワード"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                  marginTop: 1
                }}
              />
              <TextField
                fullWidth
                label="パスワード（確認）"
                type="password"
                value={re_password}
                onChange={(e) => setRePassword(e.target.value)}
                sx={{
                  marginTop: 1
                }}
              />
              {errorMessage_password}
              <Typography variant='body2' sx={{ marginTop: 2 }}>
                【注意！】仮登録が完了するとシリアルコードは、失効します。<br />メールアドレスに誤りがないか確認してください。
              </Typography>
              <Button type='submit' color='error' variant='contained' fullWidth sx={{ marginTop: 2 }}>登録</Button>
            </form>
          }
        </Paper >
      </Box >
      <Box width={'100%'} sx={{ position: 'fixed', bottom: 0 }}>
        <Typography align='center'>
          ver 0.1.1
        </Typography>
      </Box>
    </div >
  );
};

export default PreRegister;
