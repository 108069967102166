import React, { useEffect, useState } from 'react';
import { getAnnouncements } from '../services/api'; // getAnnouncement メソッドを使う
import { Box, Typography, List, ListItem, ListItemText, Paper, Divider } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';

interface Announcement {
    id: number;
    title: string;
    message: string;
    created_at: string;
}

interface UserAnnouncement {
    id: number;
    announcement: Announcement;
    is_read: boolean;
    read_at: string | null;
}

const AnnouncementList: React.FC = () => {
    const { token } = useAuth();
    const [announcements, setAnnouncements] = useState<UserAnnouncement[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchAnnouncements = async () => {
            if (token) {
                try {
                    const response = await getAnnouncements(token);
                    console.log(response)
                    setAnnouncements(response.data);
                } catch (error) {
                    setError('お知らせの取得に失敗しました。');
                }
            }
        };

        fetchAnnouncements();
    }, []);

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    return (
        <Box sx={{ marginTop: 6, marginBottom: 6, maxHeight: '90vh', overflowY: 'auto' }}>
            <Typography variant="h5" gutterBottom>
                お知らせ一覧
            </Typography>
            <List>
                {announcements.map((userAnnouncement, index) => (
                    <React.Fragment key={userAnnouncement.id}>
                        <ListItem
                            sx={{
                                paddingY: 2,
                                width: '100%',
                                margin: '0 auto', // 中央寄せ
                            }}>
                            <Box>
                                <Typography variant="caption" color="textSecondary" sx={{ display: 'block', marginBottom: 0.5 }}>
                                    {new Date(userAnnouncement.announcement.created_at).toLocaleDateString()}
                                </Typography>
                                <Typography variant="h6">{userAnnouncement.announcement.title}</Typography>
                                <div
                                    dangerouslySetInnerHTML={{ __html: userAnnouncement.announcement.message }}
                                />
                            </Box>
                        </ListItem>
                        {index < announcements.length - 1 && (
                            <Divider
                                sx={{
                                    borderStyle: 'dotted', // 点線スタイルに設定
                                    width: '90%',          // 幅を 80% に設定
                                    margin: '0 auto',      // 中央に配置
                                    borderColor: 'gray',   // 点線の色
                                    borderWidth: '1px',    // 点線の厚さ
                                }}
                            />
                        )}
                    </React.Fragment>
                ))}
            </List>
        </Box>
    );
};

export default AnnouncementList;
