import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import QRCodeComponent from './QRcode';

interface userinfo {
  name: string;
  email: string;
  id: string;
  rank: number;
  points: number;
  times: number;
}

interface IDComponentProps {
  message?: userinfo;
  estimateRank: (points: number | undefined, times: number | undefined) => number;
  seacret_key: string;
}

const ImageSelector = (rank: number | undefined) => {
  if (rank === undefined) {
    return (
      <img src='/image/top.png' style={{ width: "100%", objectFit: 'cover' }} />
    )
  }
  else {
    if (rank === 0) {
      return (
        <img src='/image/rank0.png' style={{ width: "100%", objectFit: 'cover' }} />
      )
    }
    else if (rank === 1) {
      return (
        <img src='/image/rank1.png' style={{ width: "100%", objectFit: 'cover' }} />
      )
    }
    else if (rank === 2) {
      return (
        <img src='/image/rank2.png' style={{ width: "100%", objectFit: 'cover' }} />
      )
    }
    else if (rank === 3) {
      return (
        <img src='/image/rank3.png' style={{ width: "100%", objectFit: 'cover' }} />
      )
    }
    else if (rank === 4) {
      return (
        <img src='/image/rank4.png' style={{ width: "100%", objectFit: 'cover' }} />
      )
    }
    else if (rank === 5) {
      return (
        <img src='/image/rank5.png' style={{ width: "100%", objectFit: 'cover' }} />
      )
    }
    else if (rank === 6) {
      return (
        <img src='/image/rank6.png' style={{ width: "100%", objectFit: 'cover' }} />
      )
    }
  }
}

const IDComponent: React.FC<IDComponentProps> = ({ message, estimateRank, seacret_key }) => {
  const bgColorHandler = (rank: number | undefined) => {
    if (rank !== undefined) {
      if (rank > 6) return 'white';
      else return 'black';
    }
    return 'black'
  }
  return (
    <div>
      <Box sx={{
        minWidth: "400px",
        width: { xs: "100%" },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 4,
        flexDirection: 'column',
      }}>
        <Box sx={{ width: '100vw', maxWidth: '500px', height: '300px', top: 40, position: 'fixed', zIndex: -1 }}>
          {ImageSelector(estimateRank(message?.points, message?.times))}
        </Box>
        <Box sx={{ width: '100vw', height: '300px', top: 40, position: 'fixed', zIndex: -1, }}>
          <Box sx={{
            minWidth: "400px",
            width: { xs: "100%" },
            position: 'relative',
            top: '150px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}>
            <Typography color={bgColorHandler(message?.rank)}>{message?.name} 様</Typography>
            <Paper
              sx={{
                zIndex: 0,
                width: '80vw',
                maxWidth: "400px",
                bgcolor: 'white',
                opacity: 0.8,
                marginTop: '40px'
              }}>
              <Grid container direction={'row'}>
                <Grid item xs={6} padding={1}>
                  <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    <Typography variant='body1'>
                      ポイント
                    </Typography>
                    <Typography variant='body1'>
                      {message?.points}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} padding={1}>
                  <Box display={'flex'} flexDirection={'column'} alignItems={'center'} >
                    <Typography variant='body1'>
                      来場回数
                    </Typography>
                    <Typography variant='body1'>
                      {message?.times}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
            <Paper
              sx={{
                zIndex: 0,
                width: '80vw',
                maxWidth: "400px",
                height: "35vh",
                bgcolor: 'white',
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <QRCodeComponent text={message?.email} secretKey={seacret_key} />
            </Paper>
          </Box>
        </Box>
      </Box>
      {/* <Box
        sx={{
          position: 'relative',
          padding: 3,
          margin: 5,
          width: '70vw', // 適切な幅に調整
          maxWidth: 600, // 最大幅を設定
          zIndex: 1,
        }}
      > */}
    </div >
  );
}

export default IDComponent;
