import axios from 'axios';

const API_URL = 'https://mokalive.net:8000/api';

const api = axios.create({
  baseURL: API_URL,
});

export const preRegister = async (email: string, name: string, password: string, re_password: string) => {
  return await api.post('/auth/users/', { email, name, password, re_password });
};

export const preRegisterCode = async (email: string, code: string) => {
  return await api.post('/user/update-code/', { email, code });
};

export const checkActivation = async (code: string) => {
  return await api.post('/check-activation-code/', { code });
};

export const completeRegister = async (uid: string, token: string) => {
  return await api.post('/auth/users/activation/', { uid, token });
};

export const login = async (email: string, password: string) => {
  return await api.post('/auth/jwt/create/', { email, password });
};

export const refreshAccessToken = async (refresh: string) => {
  return await api.post('/auth/jwt/refresh/', { refresh });
};

export const getProtectedResource = async (token: string) => {
  return await api.get('/auth/users/me/', {
    headers: {
      Authorization: `JWT ${token}`
    }
  });
};

export const updateProtectedResource = async (name: string, token: string) => {
  return await api.patch('/user/update/name/', {
    name,
  }, {
    headers: {
      Authorization: `JWT ${token}`
    }
  });
}

export const getProtectedResourceDetail = async (token: string) => {
  return await api.get('/user/update/', {
    headers: {
      Authorization: `JWT ${token}`
    }
  });
};

export const getUserDetails = async (email: string, token: string) => {
  return await api.get(`/user/details/${email}/`, {
    headers: {
      Authorization: `JWT ${token}`
    }
  });
}

export const updateUserPoints = async (email: string, points: number, token: string) => {
  return await api.post('/user/update-points/', {
    email,
    points
  }, {
    headers: {
      Authorization: `JWT ${token}`
    }
  });
};

export const incrementUserParticipation = async (email: string, token: string) => {
  return await api.post('/user/increment-user-participation/', {
    email,
  }, {
    headers: {
      Authorization: `JWT ${token}`
    }
  });
};

export const UpsdatePassWord = async (new_password: string, re_new_password: string, current_password: string, token: string) => {
  return await api.post('/auth/users/set_password/', {
    current_password,
    new_password,
    re_new_password,
  }, {
    headers: {
      Authorization: `JWT ${token}`
    }
  });
};

export const RequestUpdateEmail = async (email: string, token: string) => {
  return await api.post('/auth/users/reset_email/', {
    email,
  }, {
    headers: {
      Authorization: `JWT ${token}`
    }
  });
};

export const UpdateEmail = async (new_email: string, re_new_email: string, uid: string, token: string, j_token: string) => {
  return await api.post('/auth/users/reset_email_confirm/', {
    uid,
    token,
    new_email,
    re_new_email,
  }, {
    headers: {
      Authorization: `JWT ${j_token}`
    }
  });
};

export const RequestResetPassword = async (email: string) => {
  return await api.post('/auth/users/reset_password/', {
    email,
  },
  );
};

export const ResetPassword = async (new_password: string, re_new_password: string, uid: string, token: string,) => {
  return await api.post('/auth/users/reset_password_confirm/', {
    uid,
    token,
    new_password,
    re_new_password,
  }, {
  });
};

export const distributeCoupons = async (token: string) => {
  return await api.post('/user/coupons/distribute/', {}, {
    headers: {
      Authorization: `JWT ${token}`
    }
  });
};

export const updateCoupon = async (token: string, userCouponId: number) => {
  console.log(userCouponId)
  return await api.post('/use_coupon/', {
    user_coupon_id: userCouponId,
  }, {
    headers: {
      Authorization: `JWT ${token}`,
    },
  });
};

export const createAnnouncement = async (token: string, title: string, message: string, sendEmail: boolean = false) => {
  return await api.post(
    '/announcements/',  // エンドポイントは適宜変更してください
    {
      title,
      message,
      send_email: sendEmail,  // メール送信フラグ
    },
    {
      headers: {
        Authorization: `JWT ${token}`,  // 認証ヘッダーにトークンを追加
        'Content-Type': 'application/json',
      },
    }
  );
};

// Fetch the announcement list
export const getAnnouncements = async (token: string) => {
  return await api.get('/announcements/list/', {
    headers: {
      Authorization: `JWT ${token}`,
    },
  });
};

// export const getUser = async (token: string, code?: string, points?: number, times?: number) => {
//   return await api.get('/user/update/', {
//     headers: {
//       Authorization: `JWT ${token}`
//     },
//     data: {
//       code, points, times
//     }
//   });
// };
