import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import QRCodeComponent from './QRcode';
import { Button } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { updateProtectedResource } from '../services/api';
import { useNavigate } from 'react-router-dom';

interface userinfo {
  name: string;
  email: string;
  id: string;
  rank: number;
  points: number;
  times: number;
}

interface UserSettingProps {
  message?: userinfo;
  estimateRank: (points: number | undefined, times: number | undefined) => number;
  seacret_key: string;
}

const UserSetting: React.FC<UserSettingProps> = ({ message, estimateRank, seacret_key }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  return (
    <Paper
      elevation={6}
      square={false}
      sx={{
        position: 'absolute',
        height: '100%',
        width: '100vw', // 適切な幅に調整
        maxWidth: 600, // 最大幅を設定
        zIndex: 1,
        backgroundColor: 'background.paper', // 背景色を設定
      }}
    >
      <Box>
        <Typography align='center' variant='h5' marginTop={15} marginBottom={15}>各種設定</Typography>
        <Box sx={{ borderTop: 1 }}>
          <Button onClick={() => {navigate('/namechangeform')}} fullWidth sx={{ color: 'black', fontSize: 20, height: 60 }}>
            名前変更
          </Button>
        </Box>
        <Box sx={{ borderTop: 1 }}>
          <Button onClick={() => {navigate('/passchangeform')}} fullWidth sx={{ color: 'black', fontSize: 20, height: 60 }}>
            パスワード変更
          </Button>
        </Box>
        <Box sx={{ borderTop: 1 }}>
          <Button onClick={() => {navigate('/emailchangeform')}} fullWidth sx={{ color: 'black', fontSize: 20, height: 60 }}>
            メールアドレス変更
          </Button>
        </Box>
        <Box sx={{ borderTop: 1 }}>
          <Button onClick={logout} fullWidth sx={{ color: 'black', fontSize: 20, height: 60 }}>
            ログアウト
          </Button>
        </Box>
        <Box sx={{ borderTop: 1 }}>
        </Box>
      </Box>
    </Paper>
  );
}

export default UserSetting;
