import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const StaffDashboard: React.FC = () => {
    const { logout, isStaff } = useAuth();
    const navigate = useNavigate();

    const handlePointRegisterClick = () => {
        navigate("/point-register"); // ポイント登録画面への遷移
    };

    const handleAnnouncementRegisterClick = () => {
        navigate("/announcement-register"); // お知らせ登録画面への遷移
    };

    if (isStaff) {
        return (
            <Container maxWidth="md" sx={{ mt: 4 }}>
                <Typography variant="h4" align="center" gutterBottom>
                    スタッフ用
                </Typography>
                <Box display="flex" justifyContent="center" gap={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handlePointRegisterClick}
                    >
                        ポイント登録
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleAnnouncementRegisterClick}
                    >
                        お知らせ登録
                    </Button>
                </Box>
                <Box display="flex" justifyContent="center" mt={4}>
                    <Button variant="outlined" color="error" onClick={() => logout()}>
                        ログアウト
                    </Button>
                </Box>
            </Container>
        );
    }
    else {
        return <></>;
    }
};

export default StaffDashboard;
