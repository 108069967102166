import React, { useState } from 'react';
import { Box, Button, TextField, Typography, FormControlLabel, Checkbox, Snackbar } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { createAnnouncement } from '../services/api';
import { Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';


// HTML形式のメッセージに変換する関数
const convertToHtml = (text: string): string => {
    return text
        .replace(/\n/g, '<br>')  // 改行を<br>に変換
};

const CreateAnnouncementForm: React.FC = () => {
    const { token, isStaff, logout } = useAuth();
    const [title, setTitle] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [sendEmail, setSendEmail] = useState<boolean>(false);
    const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

    const navigate = useNavigate();

    const handleSubmit = async () => {
        if (!title || !message) {
            setSnackbarMessage('タイトルとメッセージは必須です。');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
            return;
        }

        const htmlMessage = convertToHtml(message);

        try {
            if (token) {
                await createAnnouncement(token, title, htmlMessage, sendEmail);
                setSnackbarMessage('お知らせが登録されました。');
                setSnackbarSeverity('success');
                setOpenSnackbar(true);
                setTitle('');
                setMessage('');
                setSendEmail(false);
            }
        } catch (error) {
            setSnackbarMessage('お知らせの登録に失敗しました。');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };
    if (isStaff) {
        return (
            <Box sx={{ maxWidth: 600, mx: 'auto', p: 2 }}>
                <Typography variant="h6" gutterBottom>
                    お知らせの登録
                </Typography>
                <TextField
                    label="タイトル"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <TextField
                    label="メッセージ"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={sendEmail}
                            onChange={(e) => setSendEmail(e.target.checked)}
                        />
                    }
                    label="メールを送信する"
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{ mt: 2 }}
                >
                    登録する
                </Button>
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={() => setOpenSnackbar(false)}
                >
                    <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
                <Box display={'flex'} justifyContent={'center'}>
                    <Button variant="outlined" color="error" onClick={() => { logout(); navigate('/'); }} sx={{ marginTop: 10 }}>Logout</Button>
                </Box>
            </Box>
        );
    }
    else {
        return <></>;
    }
};

export default CreateAnnouncementForm;
