import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { login as loginService, preRegister as preRegisterService, completeRegister as completeRegisterService, refreshAccessToken, getProtectedResourceDetail } from '../services/api';
import axios from 'axios';
import { Dispatch } from 'react';
import { SetStateAction } from 'react';

interface AuthContextProps {
  isAuthenticated: boolean;
  token: string | null;
  login: (username: string, password: string) => Promise<void>;
  preRegister: (email: string, name: string, password: string, re_password: string) => Promise<void>;
  completeRegister: (uid: string, token: string) => Promise<void>;
  logout: () => void;
  isStaff: boolean;
  setIsStaff: Dispatch<SetStateAction<boolean>>;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    const savedToken = localStorage.getItem('token');
    return savedToken !== null;
  });
  const [token, setToken] = useState<string | null>(() => localStorage.getItem('token'));
  const [refreshToken, setRefreshToken] = useState<string | null>(() => localStorage.getItem('refreshToken'));
  const [isStaff, setIsStaff] = useState<boolean>(false);

  const login = async (username: string, password: string) => {
    const response = await loginService(username, password);
    setToken(response.data.access);
    setRefreshToken(response.data.refresh);
    setIsAuthenticated(true);
    localStorage.setItem('token', response.data.access);
    localStorage.setItem('refreshToken', response.data.refresh);
    console.log(response)

    const response_detail = await getProtectedResourceDetail(response.data.access)
    await setIsStaff(response_detail.data.is_staff)
  };

  const preRegister = async (email: string, name: string, password: string, re_password: string) => {
    await preRegisterService(email, name, password, re_password);
  };

  const completeRegister = async (uid: string, token: string) => {
    await completeRegisterService(uid, token);
  };

  const logout = () => {
    setToken(null);
    setRefreshToken(null);
    setIsAuthenticated(false);
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
  };

  const refreshTokenFunction = async () => {
    if (!refreshToken) return;

    try {
      const response = await refreshAccessToken(refreshToken);
      setToken(response.data.token);
      localStorage.setItem('token', response.data.token);
    } catch (error) {
      console.error('Failed to refresh access token:', error);
      logout();
    }
  };

  useEffect(() => {
    const requestInterceptor = (config: any) => {
      if (token) {
        config.headers['Authorization'] = `JWT ${token}`;
      }
      return config;
    };

    const responseInterceptor = async (error: any) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        await refreshTokenFunction();
        return axios(originalRequest);
      }
      return Promise.reject(error);
    };

    const requestInterceptorId = axios.interceptors.request.use(requestInterceptor);
    const responseInterceptorId = axios.interceptors.response.use(response => response, responseInterceptor);

    return () => {
      axios.interceptors.request.eject(requestInterceptorId);
      axios.interceptors.response.eject(responseInterceptorId);
    };
  }, [token, refreshToken]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, token, login, preRegister, completeRegister, logout, isStaff, setIsStaff }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
