import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { isAxiosError } from 'axios';
import { Box, Paper, Typography, TextField, Button } from '@mui/material';

const Login: React.FC = () => {
  const { login } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessagePassword, setErrorMessagePassword] = useState('');
  const [errorMessageEmail, setErrorMessageEmail] = useState('');
  const [errorMessageAuth, setErrorMessageAuth] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setErrorMessageEmail('');
    setErrorMessageAuth('');
    setErrorMessagePassword('');
    try {
      await login(email, password);
      navigate('/');
    } catch (error: any) {
      console.error('Login failed:', error);
      if (isAxiosError(error) && error.response?.status === 400) {
        let message = '';
        if (error.response.data.email) {
          message += error.response.data.email.join(' ');
          setErrorMessageEmail(message);
        } else if (error.response.data.password) {
          message += error.response.data.password.join(' ');
          setErrorMessagePassword(message);
        }
      } else if (isAxiosError(error) && error.response?.status === 401) {
        setErrorMessageAuth('メールアドレスかパスワードが間違っています。');
      }
    }
  };

  return (
    <div>
      <Box
        sx={{
          position: 'relative',
          height: '100vh', // 画面全体の高さに合わせる
          display: 'flex',
          justifyContent: 'center',
          overflow: 'hidden',
        }}
      >
        <Box sx={{
          minWidth: "400px",
          width: { xs: "100%" },
          height: { xs: "30vh" },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden', // Boxの境界を超えた部分を隠す 
          position: 'fixed',
          marginTop: 4
        }}>
          <img src='/image/top.png' style={{ width: "100%", objectFit: 'cover' }} />
        </Box>
        <Paper
          elevation={6}
          square={false}
          sx={{
            position: 'absolute',
            padding: 3,
            margin: 20,
            width: '70vw', // 適切な幅に調整
            maxWidth: 600, // 最大幅を設定
            zIndex: 1,
            backgroundColor: 'background.paper', // 背景色を設定
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}>
              <img src='/image/monleadge.png' width={'80%'} />
              <TextField
                fullWidth
                label="メールアドレス"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={Boolean(errorMessageEmail)}
                helperText={errorMessageEmail}
                sx={{
                  marginTop: 3
                }}
              />
              <TextField
                fullWidth
                label="パスワード"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={Boolean(errorMessagePassword)}
                helperText={errorMessagePassword}
                sx={{
                  marginTop: 3
                }}
              />
              {errorMessageAuth && (
                <Typography color="error">{errorMessageAuth}</Typography>
              )}
              <Button
                type="submit"
                variant="contained"
                color="error"
                sx={{
                  width: '100%',
                  marginTop: 3
                }}
              >
                Login
              </Button>
              <div>
                <Box sx={{ marginTop: 3 }}>
                  <Link to="/pre-register">
                    登録がまだの方はこちら
                  </Link>
                </Box>
              </div>
              <div>
                <Box sx={{ marginTop: 3 }}>
                  <Link to="/passwordresetform">
                    パスワードを忘れた方はこちら
                  </Link>
                </Box>
              </div>
            </Box>
          </form>
        </Paper>
      </Box>
      <Box width={'100%'} sx={{ position: 'fixed', bottom: 30 }}>
        <Typography align='center'>
          ver 1.1.4
        </Typography>
      </Box>
    </div>
  );
};

export default Login;
