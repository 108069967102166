import React, { MouseEventHandler, useRef } from "react";
import { useState, useEffect } from "react";
import { Box, Button, TextField, Typography, Container, Grid, Paper } from '@mui/material';
import QRCodeScanner from "./QRcodeScanner";
import { useAuth } from "../contexts/AuthContext";
import { getUserDetails, incrementUserParticipation, updateUserPoints, getProtectedResourceDetail } from "../services/api";
import { useNavigate } from "react-router-dom";

interface userinfo {
  name: string;
  email: string;
  id: string;
  rank: string;
  points: number;
  times: number;
  is_staff: boolean;
}

const PointRegister: React.FC = () => {
  const { isStaff, token, logout, setIsStaff } = useAuth();
  const [scannedText, setScannedText] = useState<string | null>(null);
  const [userID, setUserID] = useState<string | null>(null);
  const [inputUserID, setInputUserID] = useState<string>("");
  const [message, setMessage] = useState<userinfo>();
  const [readable, setReadble] = useState<boolean>(true);
  const [additonalPoints, setAdditionalPoints] = useState<number>(0);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProtectedResource = async () => {
      if (token) {
        try {
          const response = await getProtectedResourceDetail(token);
          console.log(response);
          setIsStaff(response.data.is_staff);
        } catch (error: any) {
          console.error('Failed to fetch protected resource:', error);
          alert('エラーが発生しました。再度ログインしてください。');
          logout();
        }
      }
    };
    fetchProtectedResource();
  }, [token]);

  useEffect(() => {
    const fetchProtectedResource = async () => {
      if (token && userID) {
        try {
          const response = await getUserDetails(userID, token);
          console.log(response);
          setMessage(response.data);
        } catch (error: any) {
          console.error('Failed to fetch protected resource:', error);
        }
      }
    };
    fetchProtectedResource();
  }, [token, userID]);

  const postUserID: MouseEventHandler = () => {
    setUserID(inputUserID);
  };

  const updatePointHandler: MouseEventHandler = async () => {
    if (token && userID && message) {
      const point_sum = message.points + additonalPoints;
      try {
        await incrementUserParticipation(userID, token);
      } catch (error) {
        console.error(error);
      }
      try {
        await updateUserPoints(userID, point_sum, token);
        const response = await getUserDetails(userID, token);
        alert("付与が完了しました")
        setMessage(response.data);
        setAdditionalPoints(0);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    setReadble(false);
    setUserID(scannedText);
  }, [scannedText]);

  if (isStaff) {
    return (
      <Container maxWidth="md">
        <Paper elevation={3} sx={{ padding: 4, marginTop: 7 }}>
          {(readable) ? (
            <QRCodeScanner secretKey="moka" scannedText={scannedText} setScannedText={setScannedText} />
          ) : (
            <Box display={'flex'} justifyContent={'center'}>
              <Button variant="contained" onClick={() => setReadble(true)} sx={{ width: '50vw', height: '25vw' }}>QR読み込み開始</Button>
            </Box>
          )}
          <Box mt={2}>
            <TextField
              fullWidth
              label="メールアドレス"
              variant="outlined"
              onChange={(event) => setInputUserID(event.target.value)}
            />
            <Button fullWidth variant="contained" color="primary" onClick={postUserID} sx={{ marginTop: 2 }}>更新</Button>
          </Box>
          <Box mt={2}>
            <Typography variant="h6">{message?.name} 様</Typography>
            <Typography variant="body1">ポイント: {message?.points}</Typography>
            <Typography variant="body1">来場回数: {message?.times}</Typography>
          </Box>
          <Box mt={2}>
            <TextField
              fullWidth
              type="number"
              label="ポイント"
              variant="outlined"
              value={additonalPoints}
              onChange={(event) => setAdditionalPoints(Number(event.target.value))}
            />
            <Button fullWidth variant="contained" color="secondary" onClick={updatePointHandler} sx={{ marginTop: 2 }}>付与</Button>
          </Box>
          <Box display={'flex'} justifyContent={'center'}>
            <Button variant="outlined" color="error" onClick={() => {logout(); navigate('/');}} sx={{ marginTop: 10 }}>Logout</Button>
          </Box>
        </Paper>
      </Container>
    );
  } else {
    return <></>;
  }
};

export default PointRegister;
