import React, { useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useParams, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

interface RouteParams {
  uid: string;
  token: string;
}

const CompleteRegister: React.FC = () => {
  const { completeRegister } = useAuth();
  const params = useParams<Record<string, string>>();
  const navigate = useNavigate();

  useEffect(() => {
    const completeRegistration = async () => {
      const uid = params.uid;
      const token = params.token;

      if (!uid || !token) return;

      try {
        await completeRegister(uid, token);
        alert('本登録が完了しました。');
        navigate('/');
      } catch (error) {
        console.error('Complete registration failed:', error);
      }
    };

    completeRegistration();
  }, [params, completeRegister, navigate]);

  return (
    <div>
      <Box sx={{ marginTop: 6 }}>
        <h1>本登録中....</h1>
      </Box>
    </div>
  );
};

export default CompleteRegister;
