import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Container, Typography } from '@mui/material';
import { UpdateEmail } from '../services/api'; // 関数のインポートパスを適宜変更してください
import { useAuth } from '../contexts/AuthContext';

const EmailConfirmPage: React.FC = () => {
    const { uid, token } = useParams<{ uid: string; token: string }>();
    const [newEmail, setNewEmail] = useState<string>('');
    const [reNewEmail, setReNewEmail] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const navigate = useNavigate();
    const { token: jwtToken, login } = useAuth(); // JWTトークンとログイン関数を取得

    useEffect(() => {
        if (!jwtToken) {
            const username = prompt("ログインしてください。ユーザー名を入力してください:");
            const password = prompt("パスワードを入力してください:");

            if (username && password) {
                login(username, password)
                    .then(() => setMessage('ログインに成功しました。'))
                    .catch(() => setMessage('ログインに失敗しました。'));
            } else {
                setMessage('認証が必要です。');
            }
        }
    }, [jwtToken, login]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            if (uid && token && jwtToken) {
                await UpdateEmail(newEmail, reNewEmail, uid, token, jwtToken);
                setMessage('新しいメールアドレスが登録されました。');
            } else {
                setMessage('認証が必要です。');
            }
        } catch (error) {
            setMessage('エラーが発生しました。もう一度お試しください。');
            console.error(error)
        }
    };

    return (
        <Container maxWidth="sm">
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    mt: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                <Typography variant="h6" component="div">
                    新しいメールアドレスの登録
                </Typography>
                <TextField
                    label="新しいメールアドレス"
                    type="email"
                    variant="outlined"
                    fullWidth
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    required
                />
                <TextField
                    label="新しいメールアドレス（確認）"
                    type="email"
                    variant="outlined"
                    fullWidth
                    value={reNewEmail}
                    onChange={(e) => setReNewEmail(e.target.value)}
                    required
                />
                {message && (
                    <Typography variant="body2" color="textSecondary">
                        {message}
                    </Typography>
                )}
                <Button type="submit" variant="contained" color="primary">
                    登録
                </Button>
                <Button variant="outlined" color="secondary" onClick={() => navigate('/')}>
                    戻る
                </Button>
            </Box>
        </Container>
    );
};

export default EmailConfirmPage;
