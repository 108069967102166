import React from 'react';
import QRCode from 'qrcode.react';
import CryptoJS from 'crypto-js';

type QRCodeComponentProps = {
    text: string | undefined;
    secretKey: string;
};

const QRCodeComponent: React.FC<QRCodeComponentProps> = ({ text, secretKey }) => {
    if (text !== undefined) {
        const encryptText = (text: string) => {
            return CryptoJS.AES.encrypt(text, secretKey).toString();
        };

        const encryptedText = encryptText(text);
        return (
            <div>
                <QRCode value={encryptedText} />
            </div>
        );
    }
    else {
        return (
            <div>
            </div>
        )
    }
};

export default QRCodeComponent;
