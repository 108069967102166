import React, { useRef, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { BrowserMultiFormatReader, DecodeHintType, Result } from '@zxing/library';
import CryptoJS from 'crypto-js';

type QRCodeScannerProps = {
  secretKey: string;
  scannedText: string | null;
  setScannedText: Dispatch<SetStateAction<string | null>>;
};

const QRCodeScanner: React.FC<QRCodeScannerProps> = ({ secretKey, scannedText, setScannedText }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const decryptText = (cipherText: string, key: string) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, key);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  const handleScan = (result: Result) => {
    const text = result.getText();
    if (text) {
      const decryptedText = decryptText(text, secretKey);
      setScannedText(decryptedText);
    }
  };

  const handleError = (error: any) => {
    console.error('Error:', error);
  };

  useEffect(() => {
    const codeReader = new BrowserMultiFormatReader();
    const hints = new Map<DecodeHintType, any>();
    hints.set(DecodeHintType.POSSIBLE_FORMATS, ['QR_CODE']);

    const startScanning = () => {
      if (videoRef.current) {
        codeReader.decodeFromVideoDevice(
          null, // デフォルトデバイスを使用
          videoRef.current, // ビデオ要素
          (result, error) => {
            if (result) {
              handleScan(result);
            }
          },
        ).catch(err => {
          handleError(err);
        });
      } else {
        console.error('Video element not found');
      }
    };

    startScanning();
    

    return () => {
      codeReader.reset();
    };
  }, [secretKey]);

  return (
    <div>
      <video ref={videoRef} style={{ width: '100%' }} />
      {scannedText && (
        <div>
          {/* <h3>Scanned Text:</h3>
          <p>{scannedText}</p> */}
        </div>
      )}
    </div>
  );
};

export default QRCodeScanner;
