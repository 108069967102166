import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Login from './components/Login';
import PreRegister from './components/PreRegister';
import CompleteRegister from './components/CompleteRegister';
import Home from './components/Home';
import PrivateRoute from './components/PrivateRoute';
import { Box, Grid, Typography } from '@mui/material';
import PointRegister from './components/PointRegister';
import Header from './components/Header';
import NameChangeForm from './components/NameChangeForm';
import PasswordChangeForm from './components/PasswordChangeForm';
import EmailUpdateForm from './components/EmailChangeForm';
import EmailConfirmPage from './components/EmailConfig';
import PasswordResetConfirmPage from './components/PasswordResetConfirm';
import PasswordResetForm from './components/ResetPasswordForm';
import CreateAnnouncementForm from './components/CreateAnnouncementForm';
import StaffDashboard from './components/StaffDashboard';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Header />
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/pre-register" element={<PreRegister />} />
          <Route path="/complete-register/:uid/:token" element={<CompleteRegister />} />
          <Route path="/point-register" element={<PointRegister />} />
          <Route path="/announcement-register" element={<CreateAnnouncementForm />} />
          <Route path="/staff-dashboard" element={<StaffDashboard />} />
          <Route path="/namechangeform" element={<NameChangeForm />} />
          <Route path="/passchangeform" element={<PasswordChangeForm />} />
          <Route path="/emailchangeform" element={<EmailUpdateForm />} />
          <Route path="/reset/confirm/:uid/:token" element={<EmailConfirmPage />} />
          <Route path="/passwordresetform" element={<PasswordResetForm />} />
          <Route path="/password/reset/confirm/:uid/:token" element={<PasswordResetConfirmPage />} />
          <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
