import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import AdbIcon from '@mui/icons-material/Adb';

function Header() {
    return (
        <AppBar
            position="fixed"
            sx={{
                backgroundColor: "white",
                height: 'auto', // 高さを自動調整
            }}
        >
            <Container maxWidth="xl" sx={{ padding: 0 }}> {/* Containerのpaddingを0に設定 */}
                <Toolbar
                    disableGutters
                    sx={{
                        minHeight: 'auto', // Toolbarの最小高さを自動調整
                        padding: '4px 16px', // 内側のパディングを設定（必要に応じて調整）
                        display: 'flex',
                        alignItems: 'center', // 子要素を垂直方向に中央揃え
                    }}
                >
                    <img src='/image/MAL.png' style={{margin: 1, width:'30px'}} />
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 1,
                            marginLeft: 3,
                            flexGrow: 1,
                            fontFamily: "Poppins",
                            fontWeight: 800,
                            fontStyle: 'italic',
                            color: 'black',
                            textDecoration: 'none',
                            display: 'flex', // テキストをフレックスアイテムとして設定
                            alignItems: 'center', // テキストを垂直方向に中央揃え
                        }}
                    >
                        MON AIRLINES
                    </Typography>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Header;
